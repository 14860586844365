import { default as NextLink, LinkProps as NextLinkProps } from "next/link"
import React from "react"
import { cn } from "@Utils/theme"

interface LinkProps
  extends NextLinkProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {}

const Link = ({ className, style, children, ...props }: LinkProps): JSX.Element => {
  return (
    <NextLink
      className={cn(
        "default-font outline-none relative inline-block cursor-pointer font-normal whitespace-nowrap text-center transition duration-200 ease-in-out select-none text-primary-700 hover:text-primary-500 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-500 focus-visible:ring-opacity-50",
        className
      )}
      {...props}
    >
      {children}
    </NextLink>
  )
}

export default Link
