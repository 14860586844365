import { forwardRef } from "react"
import { IconLoader } from "@tabler/icons-react"
import { cn } from "@Utils/theme"

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  square?: boolean
  outline?: boolean
  transparent?: boolean
  loading?: boolean
  icon?: React.ReactNode
  iconPlacement?: "left" | "right"
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = "button",
      square,
      outline,
      transparent,
      loading,
      disabled,
      icon,
      iconPlacement = "left",
      className,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        type={type}
        ref={ref}
        {...props}
        className={cn(
          // basic
          "font-montserrat inline-flex items-center justify-center gap-[1.2rem] text-center border-[1px] transition-all duration-200 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-500 focus-visible:ring-opacity-50",
          // shape
          square
            ? "font-normal text-[1.2rem] leading-[1.6rem] rounded-[0.8rem] px-[3.2rem] py-[0.8rem]"
            : "font-medium text-[1.4rem] leading-[2rem] rounded-full px-[5.2rem] py-[0.8rem]",
          // colors
          square
            ? outline
              ? disabled && !loading
                ? "bg-gray-100 border-light-500 text-gray-300"
                : "text-primary-700 bg-white border-primary-600 hover:bg-primary-100 hover:text-secondary-700"
              : transparent
              ? disabled && !loading
                ? "bg-transparent border-transparent text-gray-300"
                : "text-primary-700 bg-transparent border-transparent hover:text-secondary-700"
              : disabled && !loading
              ? "bg-gray-100 border-transparent text-gray-300"
              : "text-white bg-primary-600 border-primary-600 hover:bg-secondary-400 hover:border-secondary-400 hover:text-white"
            : outline
            ? disabled && !loading
              ? "bg-gray-100 border-light-500 text-gray-300"
              : "text-secondary-400 bg-white border-primary-500 hover:bg-primary-100 hover:text-secondary-400"
            : transparent
            ? disabled && !loading
              ? "bg-transparent border-transparent text-gray-300"
              : "text-primary-700 bg-transparent border-transparent hover:text-secondary-700"
            : disabled && !loading
            ? "bg-gray-100 border-transparent text-gray-300"
            : "text-white bg-primary-600 border-primary-600 hover:bg-secondary-400 hover:border-secondary-400 hover:text-white",
          // pointer
          loading
            ? "cursor-default pointer-events-none"
            : disabled
            ? "cursor-not-allowed pointer-events-none"
            : "cursor-pointer",
          // custom
          className
        )}
      >
        {iconPlacement === "right" && children}
        {loading ? <IconLoader size={16} className="animate-spin animate-duration-[2s]" /> : icon}
        {iconPlacement === "left" && children}
      </button>
    )
  }
)

export default Button
