import { Button, ConfigProvider, Space } from "antd"
import Icon, { RightOutlined } from "@ant-design/icons"
import GoogleColorfulSvg from "@Public/svg/icons/google-colorful.svg"
import LinkedInSvg from "@Public/svg/icons/linkedin.svg"
import AppleBlackSvg from "@Public/svg/icons/apple.svg"

export interface SSOButtonsProps {
  onContinueWithEmail: () => void
}

export const SSOButtons: React.FC<SSOButtonsProps> = ({ onContinueWithEmail }) => {
  return (
    <Space direction="vertical" className="w-full my-[1rem]" size={12}>
      <ConfigProvider theme={{ token: { colorPrimary: "#0B65C2" } }}>
        <Button
          data-testid="continue-with-email-button"
          size="large"
          shape="round"
          className="!h-[4rem] xxs:!h-[4.6rem] inline-flex w-full !rounded-full justify-center items-center !text-[1.4rem] xxs:!text-[1.6rem]"
          onClick={onContinueWithEmail}
        >
          Continue with Email
          <RightOutlined className="text-[1.2rem] !ml-[1.5rem] inline-block" />
        </Button>
        <div className="relative h-[4rem]">
          <div className="absolute top-1/2 -translate-y-1/2 h-[1px] w-full bg-[#E6EAEE]"></div>
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-background md:bg-white px-[1rem] py-[1rem] text-[1.4rem] leading-[1.4rem]">
            OR
          </div>
        </div>
        <Button
          data-testid="continue-with-linkedin-button"
          size="large"
          shape="round"
          className="group/button overflow-hidden !rounded-full !h-[4rem] xxs:!h-[4.6rem] bg-[#0B65C2] border-[#0B65C2] hover:bg-[#3F96FE] hover:border-[#3F96FE] text-white hover:!text-white inline-flex w-full justify-center items-center !text-[1.4rem] xxs:!text-[1.6rem]"
          href={
            process.env.NEXT_PUBLIC_IDM_AUTH_URL +
            "/linkedin?apiKey=" +
            process.env.NEXT_PUBLIC_IDM_API_KEY
          }
        >
          <Icon
            component={LinkedInSvg}
            className="rounded-full absolute left-0 top-0 text-[#0B65C2] bg-white border-r-[1px] border-r-[#1B72E8] group-hover/button:border-[#3F96FE] w-[3.8rem] h-[3.8rem] xxs:w-[4.4rem] xxs:h-[4.4rem] text-[1.4rem] flex items-center justify-center transition duration-200 ease-in-out"
          />
          Continue with LinkedIn
        </Button>
      </ConfigProvider>
      <Button
        data-testid="continue-with-google-button"
        size="large"
        shape="round"
        className="group/button overflow-hidden !rounded-full !h-[4rem] xxs:!h-[4.6rem] bg-[#1B72E8] border-[#1B72E8] hover:bg-[#3F96FE] hover:border-[#3F96FE] text-white hover:!text-white inline-flex w-full justify-center items-center !text-[1.4rem] xxs:!text-[1.6rem]"
        href={
          process.env.NEXT_PUBLIC_IDM_AUTH_URL +
          "/google?apiKey=" +
          process.env.NEXT_PUBLIC_IDM_API_KEY
        }
      >
        <Icon
          component={GoogleColorfulSvg}
          className="rounded-full absolute left-0 top-0 bg-white border-r-[1px] border-r-[#1B72E8] group-hover/button:border-r-[#3F96FE] w-[3.8rem] h-[3.8rem] xxs:w-[4.4rem] xxs:h-[4.4rem] text-[1.4rem] flex items-center justify-center transition duration-200 ease-in-out"
        />
        Continue with Google
      </Button>
      <Button
        data-testid="continue-with-apple-button"
        size="large"
        shape="round"
        className="group/button overflow-hidden !rounded-full !h-[4rem] xxs:!h-[4.6rem] bg-[#fff] border-[#000] hover:border-[#3F96FE] text-[#000] hover:!text-[#3F96FE] inline-flex w-full justify-center items-center !text-[1.4rem] xxs:!text-[1.6rem]"
        href={
          process.env.NEXT_PUBLIC_IDM_AUTH_URL +
          "/apple?apiKey=" +
          process.env.NEXT_PUBLIC_IDM_API_KEY
        }
      >
        <Icon
          component={AppleBlackSvg}
          className="rounded-full text-black absolute left-0 top-0 bg-white border-r-[1px] border-r-[#000] group-hover/button:border-r-[#3F96FE] w-[3.8rem] h-[3.8rem] xxs:w-[4.4rem] xxs:h-[4.4rem] text-[1.4rem] flex items-center justify-center transition duration-200 ease-in-out"
        />
        Continue with Apple
      </Button>
    </Space>
  )
}

export default SSOButtons
