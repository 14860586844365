import React from "react"

import { cn } from "@Utils/theme"

export type FormRequiredMarkProps = React.HTMLAttributes<HTMLElement>

const FormRequiredMark = ({ className, ...props }: FormRequiredMarkProps) => {
  return (
    <i
      className={cn(
        "inline-block self-start align-top text-red-500 leading-none text-[2rem]",
        className
      )}
      {...props}
    >
      *
    </i>
  )
}

export default FormRequiredMark
