import Head from "next/head"
import { BlitzLayout } from "@blitzjs/next"
import useBreakpoints from "@Hooks/useBreakpoints"
import { IntercomProvider } from "./IntercomProvider"
import Link from "@Components/ui/Link"
import { cn } from "@Utils/theme"

const SignOnLayout: BlitzLayout<{ title?: string }> | any = ({ title, children }) => {
  const { isMeasured } = useBreakpoints()

  return (
    <>
      <Head>
        <title>{title || "BrokerBlocks"}</title>
      </Head>
      <IntercomProvider>
        <div
          className={cn(
            "bg-background w-full flex flex-col items-center justify-center min-h-[100svh]",
            isMeasured ? "opacity-1" : "opacity-0"
          )}
        >
          {children}
          <div className="pb-[2rem] flex flex-row items-center justify-center gap-[1.6rem]">
            <Link href="/privacy-policy" target="_blank" className="text-[1.5rem]">
              Privacy Policy
            </Link>
            <Link href="/terms-of-service" target="_blank" className="text-[1.5rem]">
              Terms of Service
            </Link>
          </div>
        </div>
      </IntercomProvider>
    </>
  )
}

export default SignOnLayout
