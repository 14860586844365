import { cn } from "@Utils/theme"

export interface FormMessageProps extends React.HTMLAttributes<HTMLParagraphElement> {
  error?: boolean
}

const FormMessage = ({ error, className, children, ...props }: FormMessageProps) => {
  return (
    <p
      className={cn(
        "font-montserrat text-[1.2rem] leading-[1.6rem]",
        error ? "text-error-500" : "text-black",
        className
      )}
      {...props}
    >
      {children}
    </p>
  )
}

export default FormMessage
