type RGB = [number, number, number]
type RGBA = [number, number, number, number]

export function hexToRgb(colorValue: string): RGB {
  colorValue = colorValue.replace(/\s/g, "").toLowerCase()

  if (colorValue.startsWith("#")) {
    const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
    const matches = hexRegex.exec(colorValue)
    if (matches && matches[1] && matches[2] && matches[3]) {
      const red = parseInt(matches[1], 16)
      const green = parseInt(matches[2], 16)
      const blue = parseInt(matches[3], 16)
      return [red, green, blue]
    }
  }

  console.warn("Invalid color value: " + colorValue)

  return [255, 255, 255]
}

export function rgbToHsl(color: RGB): RGB {
  const r = color[0] / 255
  const g = color[1] / 255
  const b = color[2] / 255
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h = (max + min) / 2
  let s = h
  let l = h

  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h * 360, s * 100, l * 100]
}

export function changeColor(
  colorValue: string,
  {
    opacity = 100,
    brightness = 100,
  }: {
    opacity?: number
    brightness?: number
  }
): string {
  colorValue = colorValue.replace(/\s/g, "").toLowerCase()

  if (colorValue.startsWith("#")) {
    const [r, g, b] = hexToRgb(colorValue)
    const red = (r * brightness) / 100
    const green = (g * brightness) / 100
    const blue = (b * brightness) / 100
    return `rgba(${red},${green},${blue},${opacity / 100})`
  } else if (colorValue.startsWith("rgb")) {
    const rgbRegex = /^rgba?\((\d+),(\d+),(\d+)\)$/i
    const matches = rgbRegex.exec(colorValue)
    if (matches && matches[1] && matches[2] && matches[3]) {
      const red = (parseInt(matches[1]) * brightness) / 100
      const green = (parseInt(matches[2]) * brightness) / 100
      const blue = (parseInt(matches[3]) * brightness) / 100
      return `rgba(${red},${green},${blue},${opacity / 100})`
    }
  }

  console.warn("Invalid color value: " + colorValue)

  return colorValue
}

export function hueRotateColor(original: string, target: string): number {
  const [or, og, ob] = hexToRgb(original)
  const [tr, tg, tb] = hexToRgb(target)
  const [oh, os, ol] = rgbToHsl([or, og, ob])
  const [th, ts, tl] = rgbToHsl([tr, tg, tb])

  const hueRotate = th - oh

  return hueRotate
}

export function cn(...classes: (string | null | undefined | boolean)[]): string {
  return classes.filter((c) => typeof c === "string" && c.trim()).join(" ")
}
