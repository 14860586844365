import { cn } from "@Utils/theme"
import FormRequiredMark from "./FormRequiredMark"

export interface FormLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean
}

const FormLabel = ({ className, children, required, ...props }: FormLabelProps) => {
  return (
    <label
      className={cn("font-[Inter] block text-[1.2rem] leading-[1.6rem] text-black", className)}
      {...props}
    >
      {children}
      {required && <FormRequiredMark className="ml-[2px] !text-[1.4rem]" />}
    </label>
  )
}

export default FormLabel
