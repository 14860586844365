import React from "react"
import LogoTextSvg from "@Public/svg/bb-logo-text.svg"
import { cn } from "@Utils/theme"
import Image from "next/image"

export interface LogoProps {
  onClick?: () => void
  className?: string
  style?: React.CSSProperties
}

const Logo: React.FC<LogoProps> = ({ onClick, className, style }) => {
  return (
    <div className={cn("inline-flex items-center", className)} style={style}>
      <span
        role={onClick ? "button" : undefined}
        tabIndex={onClick ? 0 : undefined}
        onClick={onClick}
        className="inline-flex items-center gap-[1.6rem]"
      >
        <Image
          src="/images/bb-logo-blue.png"
          alt="logo"
          width={28}
          height={32}
          style={{ width: "28px", height: "32px" }}
        />
        <LogoTextSvg height="2.4rem" width="15.6rem" fill="#111f7f" style={{ color: "#111f7f" }} />
      </span>
    </div>
  )
}

export default Logo
