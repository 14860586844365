import { cn } from "@Utils/theme"

export interface FormItemProps extends React.HTMLAttributes<HTMLDivElement> {}

const FormItem = ({ className, children, ...props }: FormItemProps) => {
  return (
    <div className={cn("flex flex-col gap-[0.4rem]", className)} {...props}>
      {children}
    </div>
  )
}

export default FormItem
